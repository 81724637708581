export function Map() {
    return (
        <>
            <div className="map">
                <p className={'map__title'}>Мы на карте</p>
                <div className={'map__feedback'}><a
                    className={'map__text map__text_one'}
                    href="https://yandex.ru/maps/org/keune/1041706825/?utm_medium=mapframe&utm_source=maps">Keune</a><a
                    className={'map__text map__text_two'}
                    href="https://yandex.ru/maps/213/moscow/category/beauty_salon/184105814/?utm_medium=mapframe&utm_source=maps">Салон
                    красоты в Москве</a><a
                    className={'map__text map__text_three'}
                    href="https://yandex.ru/maps/213/moscow/category/cosmetology/892508964/?utm_medium=mapframe&utm_source=maps">Косметология
                    в Москве</a>
                    <iframe
                        className={'map__iframe'}
                        src="https://yandex.ru/map-widget/v1/?ll=37.497448%2C55.800451&mode=search&oid=1041706825&ol=biz&z=14.66"
                        frameBorder="1" allowFullScreen="true"></iframe>
                </div>
                <div className={'map__undertaker'}>
                    <div className={'map__undertaker map__undertaker_feedback'}>
                        <iframe className={'map__iframe map__iframe_undertaker'}
                            src="https://yandex.ru/maps-reviews-widget/1041706825?comments"></iframe>
                        <a className={'map__text map__text_undertaker'} href="https://yandex.ru/maps/org/keune/1041706825/" target="_blank" rel="noreferrer">
							Keune на карте Москвы — Яндекс Карты</a>
					</div>
                    <div className="map__icons">
                        <form className={'map__form'} action={'https://yandex.ru/maps/org/1041706825'} target={'_blank'}>
                            <button className={'map__button'}><img className={'map__button_ya'} src={require("../../images/YaMaps.png")} alt=""/></button>
                        </form>
                        <form className={'map__form'} action={'https://2gis.ru/moscow/geo/4504127919397364'} target={'_blank'}>
                            <button className={'map__button'}><img className={'map__button_gis'} src={require("../../images/2GIS.png")} alt=""/></button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
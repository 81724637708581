export function Stock() {
	return (
		<>
		<div className={'stock'}>
			<h1 className={'stock__title'}>Студия красоты KEUNE</h1>
			<p className={'stock__address'}>г. Москва, ул. Маршала Конева, д. 5</p>
			<div className={"stock__info"}>
				<div className={"stock__time"}>
					<p className={'stock__times'}>
						10:00 - 22:00
					</p>
					<p className={'stock__text'}>
						без выходных
					</p>
				</div>
				<div className={"stock__numbers"}>
					<a href={'tel:+79854449304'} className={'stock__number'}>+7 (985) 444-93-04</a>
					<a href={'tel:+74991992208'} className={'stock__number'}>+7 (499) 199-22-08</a>
				</div>
			</div>
		</div>
		</>
	)
}
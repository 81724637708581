import {NavLink} from "react-router-dom";

const topScroll = () => {
    window.scrollTo({
        left: 0,
        top: -4000,
        behavior: "smooth"
    })
}

export function ActualStocks() {
    return (
        <>
            <div className="actual-stocks">
                <h3 className="actual-stocks__title">Наши акции</h3>
                <div className="actual-stocks__content">
                    <div className="actual-stocks__example">
                        <video src={require('../../video/video-20-08-23-04-17.mov')} controls className={'actual-stocks__video'}/>
                        <img className={'actual-stocks__image'} src={require('../../images/stock1.jpg')} alt={'Lebel'}/>
                    </div>
                    <p className="actual-stocks__text">
                        <p className={'actual-stocks__name'}>Акция сентября скидка 10%!</p>
                        <p className={'actual-stocks__name'}>Абсолютное счастье для волос от Lebel</p> - это уникальная процедура восстановления волос
                        на молекулярном уровне, а также это комплексный уход за поврежденными волосами и кожей головы.
                        Комплекс включает в себя 11 потрясающих средств, которые по порядку наносятся
                        на кожу головы и волосы.
                        Лечебную программу "Абсолютное счастье для волос" рекомендуется проводить курсом
                        (2-4 процедуры), в зависимости от степени повреждения волос.
                        Результат процедуры:
                        Восстановление молекулярной влаги в структуре волос и коже головы
                        <p>
                            <img className={'actual-stocks__images'} src={require('../../images/heart.svg').default} alt={'heart'}/>
                            Восстановление молекулярных связей внутри волоса
                        </p>
                        <p>
                        <img className={'actual-stocks__images'} src={require('../../images/heart.svg').default} alt={'heart'}/>
                        Восстановление внешней структуры волос, укрепление, придание эластичности и сияющего блеска
                        </p>
                        <p>
                        <img className={'actual-stocks__images'} src={require('../../images/heart.svg').default} alt={'heart'}/>
                        Увлажнение и питание волос
                        Подарите своим волосам «Абсолютное счастье» в студии красоты KEUNE
                        </p>
                        <p className={"actual-stocks__undertaker"}>* Процедура на видео выполняется стилистом Юлией</p>
                    </p>
                </div>
                <NavLink to={'/'} onClick={topScroll} className={'return'}>На главную</NavLink>
            </div>
        </>
    )
}
import Arrow from "../../images/Arrow 2 (Stroke).svg";
import {elements} from "../../utils/elements";
import React from "react";

export function List(props) {

    const reference = React.useRef();

    console.log(props)

    function renderElement() {
        return props.texts.map((element, index) => {
                console.log(element)
                    return (<p className={'services__list services__list_content'}>
                        {element.value}
                    </p>)
        })
    }

    function toggleOpen() {
        props.handleOpenList(props.id)
        console.log(props.isOpenList)
    }

    return (
        <>
            <li className={'services__list services__list_element'}>
                <div className={'services__elements'}>
                    <p className={`services__name ${props.isOpenList ? `services__name_active` : ''}`}>{props.title}<img src={props.image} className={'services__image'} alt={'svg'}/></p>
                    <button className={`services__button ${props.isOpenList ? `services__button_active` : ''}`} onClick={toggleOpen}><img id={props.title} src={Arrow} alt=""/></button>
                </div>
                <div ref={reference} className={`services__list services__list_price ${props.isOpenList ? `services__list_opened`: ''}`}>
                    {
                        renderElement(elements)
                    }
                </div>
            </li>
        </>
    )
}
import Sofa from '../images/Sofa.png'
import WomenWithTubes from '../images/WomenWithTubes.png'
import Picture from '../images/Picture.png'

export const infoTextSmall = [
    {
        "image": Sofa,
        "title": 'Диван',
    },
    {
        "image": WomenWithTubes,
        "title": 'Девушка с тюбиками',
    },
    {
        "image": Picture,
        "title": 'Картина',
    }
]
export const examples = [
    {
        "image": require('../images/image-17-07-23-01-43.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-1.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-2.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-3.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-4.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-5.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-6.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-7.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-8.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-9.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-10.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-11.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-12.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-13.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-14.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-15.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-16.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-17.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-18.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-19.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-20.png')
    },
    {
        "image": require('../images/image-17-07-23-01-43-21.png')
    },
    {
        "image": require('../images/image-24-08-23-04-11-3.png')
    },
    {
        "image": require('../images/image-24-08-23-04-11.png')
    },
    {
        "image": require('../images/image-24-08-23-04-11-2.png')
    },
    {
        "image": require('../images/image-24-08-23-04-11-1.png')
    }
]
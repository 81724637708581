import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css/bundle'
import 'swiper/css/grid'
import {Autoplay, Navigation, Pagination} from "swiper/modules";


export function Info(props) {
	return (
		<>
			<div className={'info'}>
				<div className={'info__block'}>
					<div className={'info__swiper'}>
					<Swiper
						centeredSlides = {true}
						modules={[Autoplay, Navigation, Pagination]}
						slidesPerView={1}
						autoplay={{
							delay: 4000,
							disableOnInteraction: false
						}}
					>
						{
							props.smallSlides.map((slide) => (
								<SwiperSlide key={slide.image}><img src={slide.image} alt={slide.title}/></SwiperSlide>
							))
						}
					</Swiper>
					</div>
					<div className={'info__text info__text_higher'}>
						<h1 className={'info__title info__title_higher'}>О нас</h1>
						<p className={'info__main info__main_higher'}>

							<img src={require('../../images/heart.svg').default} alt={'heart'}/> Студия красоты Keune - место Вашего преображения и вдохновения каждый день по любому поводу
								и без него.
							<p className={'info__main info__main_higher info__main_second'}>
								<img src={require('../../images/heart.svg').default} alt={'heart'}/> Место заботы и любви к себе, где команда специалистов с многолетним опытом
								раскроет вашу истинную красоту, угостит вкуснейшим кофе подарит классное настроение!</p>
							<p className={'info__main info__main_higher info__main_second'}><img src={require('../../images/heart.svg').default} alt={'heart'}/> KEUNE - многопрофильная студия красоты услуг парикмахерского зала, ногтевого сервиса, косметологии</p>
						</p>
					</div>
				</div>
				<div className={'info__block'}>
					<div className={'info__text info__text_below'}>
						<h2 className={'info__title info__title_below'}>Почему нас выбирают</h2>
						<p className={'info__main'}>
							<img src={require('../../images/ok.svg').default} alt={'ok'}/> Команда студии состоит из высококвалифицированных стилистов, колористов, мастеров ногтевого
								сервиса, косметологов с медицинским образованием
						</p>
						<p className={'info__main'}>
							<img src={require('../../images/ok.svg').default} alt={'ok'}/> Наши стилисты используют сертифицированную косметику брендов Keune (Голландия),
								Lebel (Япония). Закупки делаем напрямую у производителя
						</p>
						<p className={'info__main'}>
							<img src={require('../../images/ok.svg').default} alt={'ok'}/> У нас действует система скидок по гостевым и именным картам, сертификатам
						</p>
						<p className={'info__main '}>
							<img src={require('../../images/ok.svg').default} alt={'ok'}/> В студии всегда чисто, ведется график уборки и дезинфекции
						</p>
						<p className={'info__main'}>
							<img src={require('../../images/ok.svg').default} alt={'ok'}/> Есть комфортная зона ожидания с чаем и кофе для Вас
						</p>
					</div>
					<div className={'info__swiper'}>
						<Swiper
							centeredSlides = {true}
							modules={[Autoplay, Navigation, Pagination]}
							slidesPerView={1}
							autoplay={{
								delay: 4000,
								disableOnInteraction: false
							}}
						>
							{
								props.bigSlides.map((slide) => (
									<SwiperSlide className={'info__swiper'} key={slide.image}><img src={slide.image} alt={slide.title}/></SwiperSlide>
								))
							}
						</Swiper>
					</div>

				</div>
				<div className={'info__block info__block_third'}>
					<h3 className={'info__title info__title_below info__title_below_second'}>ЗАПИСЬ</h3>
					<div className={'info__main info__main_record'}>
						Предварительно просим Вас записаться через форму <a className={'info__main-link'} href={'http://arn.su/AKZ'} target={'_blank'} rel="noreferrer">ЗАПИСЬ</a>
						или по телефонам:
					</div>
					<div className={'info__main info__main_number'}>
						<a href={'tel: 79854449304'} className={'info__main-number'}>+7 (985) 444-93-04</a>
						<a href={'tel: 74991992208'} className={'info__main-number'}>+7 (499) 199-22-08</a>
					</div>
					<div className={'info__main info__main_undertaker'}>
						<p className={'info__wait'}>Ждём Вас каждый день с 10:00 до 22:00 по адресу: г. Москва, ул. Маршала Конева, д. 5</p>
						<p className={'info__wait'}>Вход с торца здания</p>
					</div>
					<div className={'info__button info__button_theme'}>
						<button
							className={'info__button'}
							onClick={props.onExamplePopup}
						>
							Примеры работ мастеров
						</button>
						<img className={'info__button-cursor'} alt={'курсор'} src={require('../../images/cursorPointer.svg').default}/>
					</div>
				</div>
			</div>
		</>
	)
}
export const listNames = [
    {
        "title": "Стрижки ",
        "image": require('../images/scissors.svg').default
    },
    {
        "title": "Окрашивания",
        "image": require('../images/colors.svg').default
    },
    {
        "title": "Уходы",
        "image": require('../images/cares.svg').default
    },
    {
        "title": "Укладки, Мытье",
        "image": require('../images/hairs.svg').default
    },
    {
        "title": "Маникюр/Педикюр",
        "image": require('../images/hands.svg').default
    },
    {
        "title": "Косметология",
        "image": require('../images/cosmetic.svg').default
    },
    {
        "title": "Биоэпиляция",
        "image": require('../images/bio.svg').default
    },
    {
        "title": "Коррекция бровей",
        "image": require('../images/brow.svg').default
    },
]
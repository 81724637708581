import Calendar from '../images/Calendar.png'
import Studio from '../images/Studio.png'
import Cards from '../images/Cards.png'
import StockCards from '../images/stockCards.png'
import Women from '../images/Women.png'
import Tovar from '../images/Tovar.png'
import Bottles from '../images/Bottles.png'
export const stockSlides = [
  {
    "image": Calendar,
    "title": 'Календарь',
  },
  {
    "image": Studio,
    "title": 'Студия',
  },
  {
    "image": Cards,
    "title": 'Карточки',
  },
  {
    "image": StockCards,
    "title": 'Скидочные карточки',
  },
  {
    "image": Women,
    "title": 'Женщина',
  },
  {
    "image": Tovar,
    "title": 'Товар',
  },
  {
    "image": Bottles,
    "title": 'Бутыльки',
  },
]
export const elements = [
        [
            {
                "value": "Женская стрижка до уха - 1500₽"
            },
            {
                "value": "Женская стрижка до плеч - 1700₽"
            },
            {
                "value": "Женская стрижка до лопаток - 1900₽"
            },
            {
                "value": "Женская стрижка ниже лопаток - 2200₽"
            },
            {
                "value": "Мужская стрижка машинкой \n" +
                    "(1/2 насадки) - 600/700₽"
            },
            {
                "value": "Мужская стрижка модельная \n" +
                    "по длине волос - 1200 — 1500₽\n"
            },
            {
                "value": "Детская стрижка (до 10 лет) - 800₽"
            },
            {
                "value": "Пенсионная стрижка \n" +
                    "(до 12:00) - 600₽\n"
            },
        ],
        [
            {
                "value": "Окрашивание корней - 3500"
            },
            {
                "value": "Окрашивание в тон\n" +
                    "по длине - 3600 — 5500₽"
            },{
                "value": "Тонирование\n" +
                    "по длине - 3600 — 5500₽ "
            },{
                "value": "Мелирование полное\n" +
                    "по длине 3500 — 5700₽"
            },{
                "value": "Мелирование частичное\n" +
                    "по длине 2200 — 3800₽"
            },{
                "value": "Мелирование\n" +
                    "прикорневое - 5200₽"
            },{
                "value": "контуринг с тонированием \n" +
                    "зоны контуринга - 5000₽"
            },{
                "value": "сложное\n" +
                    "окрашивание - 5000 — 8300₽"
            },{
                "value": "блондирование\n" +
                    "полное - 3600 — 5300₽"
            },{
                "value": "блондирование\n" +
                    "прикорневой зоны - 3600 — 5300₽"
            },{
                "value": "колорирование - 4100 — 6600₽"
            },{
                "value": "художественное \n" +
                    "окрашивание - 9000 — 15000₽"
            },{
                "value": "декапирование - 5000 — 10000₽"
            },{
                "value": "осветление краской\n" +
                    "корней - 4000 — 5000₽"
            },{
                "value": "осветление краской\n" +
                    "полное - от 5000₽"
            },
        ],
        [
            {
                "value": "Маска - 500 — 700₽"
            },
            {
                "value": "комплексный\n" +
                    "глубокий уход - 3300 — 3700₽"
            },
            {
                "value": "«абсолютное счастье\n" +
                    " для волос»- 1800 — 6000₽"
            },
        ],
        [
            {
                "value": "Мытье головы - 450 — 700₽"
            },
            {
                "value": "сушка феном - 400 — 700₽"
            },
            {
                "value": "укладка дневная - 1000 — 1500₽"
            },
            {
                "value": "укладка феном\n" +
                    "на браш - 1200 — 2500₽"
            },
            {
                "value": "укладка на горячий\n" +
                    "инструмент - 2000 — 2700₽"
            },
        ],
        [
            {
                "value": "обработка пальцев ног - 1000₽"
            },
            {
                "value": "обработка пальцев ног - 1000₽"
            },
            {
                "value": "спа-уход для рук - 600₽"
            },
            {
                "value": "маникюр детский - 650₽"
            },
            {
                "value": "деформированный\n" +
                    "вросший ноготь - 700₽"
            },
            {
                "value": "коррекция формы ногтя\n" +
                    "для дам - 550₽"
            },
            {
                "value": "коррекция формы ногтя\n" +
                    "для мужчин - 700₽"
            },
            {
                "value": "педикюр + гель лак - 2400₽"
            },
            {
                "value": "Маникюр классический - 1000₽"
            },
            {
                "value": "Маникюр\n" +
                    "комбинированный - 1000₽"
            },
            {
                "value": "Маникюр аппаратный - 1100₽"
            },
            {
                "value": "снятие гель лака - 400₽"
            },
            {
                "value": "снятие лака - 150₽"
            },
            {
                "value": "маникюр + покрытие\n" +
                    "гель лак - 2000₽"
            },
            {
                "value": "покрытие лечебное - 200₽"
            },
            {
                "value": "покрытие лаком - 400₽"
            },
            {
                "value": "покрытие гель лаком - 1500₽"
            },
            {
                "value": "покрытие френч - 400₽"
            },
            {
                "value": "дизайн - от 50₽ за ноготь"
            },
            {
                "value": "мужской маникюр - 1300₽"
            },
            {
                "value": "мужской педикюр - 2300₽"
            },
            {
                "value": "педикюр классический - 1800₽"
            },
            {
                "value": "педикюр\n" +
                    "комбинированный - 1800₽"
            },
            {
                "value": "педикюр аппаратный - 2000₽"
            },
            {
                "value": "укрепление ногтевой\n" +
                    "пластины (пудра) - 600₽"
            },
            {
                "value": "укрепление ногтевой\n" +
                    "пластины (акригель) - 1000₽"
            },
            {
                "value": "укрепление ногтевой\n" +
                    "пластины (гель) - 1600₽"
            },
            {
                "value": "наращивание ногтей - 3500₽"
            },
            {
                "value": "коррекция нарощенных \n" +
                    "ногтей - 2700₽"
            },
            {
                "value": "экспресс-маникюр - 700₽"
            },
            {
                "value": "экспресс-педикюр - 1300₽"
            },
        ],
        [
            {
                "value": "массаж рук\n" +
                    "(15 минут) для дам - 500₽"
            },
            {
                "value": "массаж ног\n" +
                    "(15 минут) для дам - 600₽"
            },
            {
                "value": "массаж рук\n" +
                    "(15 минут) для мужчин - 600₽"
            },
            {
                "value": "массаж ног\n" +
                    "(15 минут) для мужчин - 700₽"
            },
            {
                "value": "чистка лица\n" +
                    "гигиеническая - 3300₽"
            },
            {
                "value": "чистка лица\n" +
                    "комбинированная - 3600₽"
            },
            {
                "value": "чистка лица\n" +
                    "ультразвуковая - 3200₽"
            },
            {
                "value": "пилинг с гликолевой\n" +
                    "кислотой (inspia) - 2700₽"
            },
            {
                "value": "индивидуальный уход - от 3000₽"
            },
            {
                "value": "индивидуальный уход \n" +
                    "+ альгинатная маска - 3500₽"
            },
            {
                "value": "альгинатная маска - 1500₽"
            },
            {
                "value": "массаж лица по маслу - 1200₽"
            },
            {
                "value": "прокол ушей - 1200₽"
            },
            {
                "value": "прокол одного уха - 800₽"
            },
            {
                "value": "пирсинг хряща уха - 2000₽"
            },
            {
                "value": "замена сережек - 200₽"
            },
            {
                "value": "серьги медицинские - 400₽"
            },
        ],
        [
            {
                "value": "голень(ноги до колена) - 800₽"
            },
            {
                "value": "бедро(ноги выше колена) - 800₽"
            },
            {
                "value": "ноги полностью - 1500₽"
            },
            {
                "value": "руки до локтя - 800₽"
            },
            {
                "value": "руки полностью - 1500₽"
            },
            {
                "value": "верхняя губа - 500₽"
            },
            {
                "value": "подбородок - 500₽"
            },
            {
                "value": "нос, уши - 500₽"
            },
            {
                "value": "баки - 700₽"
            },
            {
                "value": "подмышечная зона - 700₽"
            },
            {
                "value": "бикини классическое - 1500₽"
            },
            {
                "value": "бикини полуглубокое - 1700₽"
            },
            {
                "value": "бикини глубокое - 1950₽"
            },
            {
                "value": "бикини глубокое - 1950₽"
            },
            {
                "value": "ягодицы - 700₽"
            },
            {
                "value": "спина - 1000₽"
            },
            {
                "value": "живот - 800₽"
            },
            {
                "value": "грудь - 1000₽"
            },
        ],
        [
            {
                "value": "коррекция бровей - 500₽"
            },
            {
                "value": "окрашивание \n" +
                    "бровей, ресниц - 500₽"
            },
            {
                "value": "2 в 1 (коррекция \n" +
                    "+ окрашивание бровей) - 1000₽"
            },
            {
                "value": "3 в 1 (коррекция + окрашивание \n" +
                    "бровей и ресниц) - 1500₽"
            },
            {
                "value": "снятие нарощенный ресниц - 800₽"
            },
            {
                "value": "удаление милеума - 300₽"
            },
        ],
]
import {elements} from "../../utils/elements";
import React from "react";
import {listNames} from "../../utils/listNames";
import {List} from "../list/List";
import {NavLink} from "react-router-dom";

const topScroll = () => {
	window.scrollTo({
		left: 0,
		top: -4000,
		behavior: "smooth"
	})
}

export function Services() {

	const [ isOpenList,  setIsOpenList ] = React.useState({});
	const [ lastOpenListId, setLastOpenListId ] = React.useState(null)

	console.log(isOpenList)

	function handleOpenList(index) {
		setIsOpenList(isOpenList[index] ? {} : {[index]: true})
		console.log(isOpenList)
	}

	return (
		<>
			<div className="services">
				<div className="services__main">
					<h3 className={'services__title'}>
						Наши услуги
					</h3>
					<ul className={'services__list'}>
						<li className={'services__list services__list_element'}>
							{
								listNames.map((item, index) => {
										return (
											<List
											texts={elements[index]}
											isOpenList={isOpenList[index]}
											setIsOpenList={setIsOpenList}
											lastOpenListId={lastOpenListId}
											setLastOpenListId={setLastOpenListId}
											handleOpenList={handleOpenList}
											title={item.title}
											image={item.image}
											id={index}
										/>
										)
									}
								)
							}
						</li>
					</ul>
				</div>
				<NavLink to={'/'} onClick={topScroll} className={'return'}>На главную</NavLink>
			</div>
		</>
	)
}

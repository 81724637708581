import React from "react";
import './App.css';
import {stockSlides} from "./utils/stockSlides";
import {Header} from "./components/header/Header";
import {infoTextSmall} from "./utils/infoTextSmall";
import {infoTextBig} from "./utils/infoTextBig";
import {Footer} from "./components/footer/Footer";
import {Main} from "./components/main/Main";
import {Route, Routes, useNavigate} from 'react-router-dom'
import {Services} from "./components/services/Services";
import {ActualStocks} from "./components/actualStocks/ActualStocks";


function App() {

    const [ isExamplesPopupOpen, setIsExamplesPopupOpen ] = React.useState(false);



    function handleExamplePopup() {
        setIsExamplesPopupOpen(true)
    }

    function closePopup() {
        setIsExamplesPopupOpen(false)
    }

  return (
      <>
          <React.Fragment>
              <Header/>
          </React.Fragment>
          <Routes>
              <Route path={'/'} element={
                  <Main
                      onExamplePopup = {handleExamplePopup}
                      isopen={isExamplesPopupOpen}
                      onClose={closePopup}
                      slides={stockSlides}
                      smallSlides={infoTextSmall}
                      bigSlides={infoTextBig}
                  />}
              />
              <Route
                  path={'/stocks'}
                  element={
                    <ActualStocks/>
                  }
              />
              <Route
                  path={'/services'}
                  element={
                  <Services/>
              }
              />
          </Routes>
          <Footer/>
      </>
  );
}

export default App;

import {Popup} from "../popup/Popup";
import {examples} from "../../utils/examples";


export function Examples(props) {
	return (
		<>
			<Popup
				onClose={props.onClose}
				isOpen={props.isOpen}
				examples={examples}
			/>
		</>
	)
}
import Hairs from '../images/Hairs.png'
import KeuneColor from '../images/KeuneColor.png'
import MirrorWithBottles from '../images/MirrorWithBottles.png'
import Tubes from '../images/Tubes.png'

export const infoTextBig = [
    {
        "image": Hairs,
        "title": 'Волосы',
    },
    {
        "image": KeuneColor,
        "title": 'Цвета',
    },
    {
        "image": MirrorWithBottles,
        "title": 'Зеркало с бутыльками',
    },
    {
        "image": Tubes,
        "title": 'Тюбики',
    },
]
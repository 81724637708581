import {Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

export function Popup(props) {
	return (
		<>
			<div className={`examples ${props.isOpen ? `examples_opened`: ""}`}>
				<button className={'examples__close'} onClick={props.onClose}></button>
				<Swiper
					className={'examples__slider'}
					modules={[Pagination, Navigation]}
					slidesPerView={2}
					navigation
					pagination={true}
				>
					{
						props.examples.map((slide) => (
							<SwiperSlide  key={slide.image}><img className={'examples__images'} src={slide.image} alt=""/></SwiperSlide>
						))
					}
				</Swiper>
			</div>
		</>
	)
}
import logo from '../../images/KeuneLogo.svg'
import {NavLink} from "react-router-dom";
export function Header(props) {
	const footerScroll = () => {
		window.scrollTo({
			left: 0,
			top: 4000,
			behavior: "smooth"
		})
	}

	const topScroll = () => {
		window.scrollTo({
			left: 0,
			top: -4000,
			behavior: "smooth"
		})
	}

	return (
		<>
			<header className={'header'}>
				<p className={'header__number'}>
					<a href={'tel:+79854449304'} className={'header__numbers'}>+7 (985) 444-93-04</a>
					<a href={'tel:+74991992208'} className={'header__numbers'}>+7 (499) 199-22-08</a>
				</p>
				<div className={'list'}>
					<a href={'http://arn.su/AKZ'} target={'_blank'} className={'form header__list header__list_element '} rel="noreferrer">ONLINE-ЗАПИСЬ</a>
					<NavLink to={'/services'} onClick={topScroll} className={'header__list header__list_element'}>УСЛУГИ</NavLink>
					<NavLink className={'header__list header__list_element'} onClick={topScroll} to={'/stocks'}>АКЦИИ</NavLink>
					<button onClick={footerScroll} className={'header__list header__list_element'}>КОНТАКТЫ</button>
			</div>
				<img className={'header__logo'} src={logo} alt={'Логотип'}/>
			</header>
		</>
	)
}
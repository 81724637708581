export function Footer() {
	return (
		<>
			<div className={'footer'} id={'footer'}>
				<p className={'footer__text footer__text_number'}>
					<a href={'tel: 79854449304'} className={'footer__text-number'}>+7 (985) 444-93-04</a>
					<a href={'tel: 74991992208'} className={'footer__text-number'}>+7 (499) 199-22-08</a>
				</p>
				<img className="footer__logo" alt={'Логотип'} src={require('../../images/FooterLogo.svg').default}/>
				<div className="footer__text">
					<p className={'footer__text'}>
						keunestudio@yandex.ru
					</p>
					<div className="footer__icons">
						<p className={'footer__icon-info'}>Наш инстаграм &rarr;</p><a className={'footer__instagram-box'} href={'https://instagram.com/keune_studio_moscow?igshid=OGQ5ZDc2ODk2ZA=='} target={'_blank'} rel="noreferrer"><img className={'footer__instagram'} alt={'Инстаграмм'} src={require('../../images/Instagramm.svg').default} /></a>
					</div>
				</div>
			</div>
		</>
	)
}
import {Stock} from "../stock/Stock";
import {Info} from "../info/Info";
import {Examples} from "../examples/Examples";
import {Map} from "../map/Map";
import React from "react";

export function Main(props) {
	return (
		<>
			<Stock slides={props.slides}/>
			<Info
				smallSlides={props.smallSlides}
				bigSlides={props.bigSlides}
				onExamplePopup={props.onExamplePopup}
			/>
			<Examples
				isOpen={props.isopen}
				onClose={props.onClose}
			/>
			<Map/>
		</>
	)
}
